import moment from 'moment'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'assets/css/light-theme.css'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE,
  })
}

// Moment Spanish configuration
moment.updateLocale('en', {
  weekdaysMin: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sab'],
  monthsShort: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
})
