import axios from 'axios'
import Utils from 'utils'
import { errorModalAlert } from 'utils/ModalAlert'
import * as Sentry from '@sentry/react'

export const HTTP = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

export const setBearerToken = (jwtToken) => {
  const { headers } = HTTP.defaults

  HTTP.defaults.headers = {
    ...headers,
    Authorization: `Bearer ${jwtToken}`,
  }
}

// Add a request interceptor
HTTP.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    Utils.cleanRequestBody(config.data)
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

HTTP.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  async function (error) {
    if (error.message === 'Network Error') {
      errorModalAlert('Has perdido conexión a internet', 'Conexión perdida')
    } else {
      Sentry.captureException(error)
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  },
)
