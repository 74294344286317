import React, { createContext, useState } from 'react'

export const ClientState = createContext({
  doingPayment: false,
  setDoingPayment: () => {},
  method: null,
  setMethod: () => {},
  ignorePendingPayment: null,
  setIgnorePendingPayment: () => {},
  cupon: null,
  setCupon: () => {},
})
const ClientAppContext = ({ children }) => {
  const [method, setMethod] = useState(null)
  const [doingPayment, setDoingPayment] = useState(false)
  const [ignorePendingPayment, setIgnorePendingPayment] = useState(false)
  const [cupon, setCupon] = useState(null)

  return (
    <ClientState.Provider
      value={{
        doingPayment,
        setDoingPayment,
        method,
        setMethod,
        ignorePendingPayment,
        setIgnorePendingPayment,
        cupon,
        setCupon,
      }}
    >
      {children}
    </ClientState.Provider>
  )
}

export default ClientAppContext
