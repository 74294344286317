import React from 'react'
import { Provider } from 'react-redux'
import store from '../redux/store'
import { BrowserRouter as Router } from 'react-router-dom'
import Views from '../views'
import { Switch } from 'react-router-dom'
import { Route } from 'react-router-dom/cjs/react-router-dom.min'
import AppContext from './AppContext'

const MainView = () => {
  return (
    <AppContext>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/">
              <Views />
            </Route>
          </Switch>
        </Router>
      </Provider>
    </AppContext>
  )
}

export default MainView
