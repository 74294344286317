import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Switch } from 'react-router-dom'
import ProtectedRoute from 'auth/protected-route'
import MainView from 'views/MainView'
import './boostrap'

// Styles
import './app.scss'

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <ProtectedRoute path="/" component={MainView} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
