import React from 'react'
import { APP_NAME, APP_VERSION } from 'configs/AppConfig'
import { Col, Row, Tooltip } from 'antd'

export default function Footer() {
  return (
    <footer
      className="footer bg-light p-3"
      style={{ margin: '0px 0px 0px 0px' }}
    >
      <FooterContent />
    </footer>
  )
}

export function FooterContent() {
  return (
    <Row gutter={10} style={{ width: '100%' }}>
      <Col span={12}>
        Copyright &copy; {`${new Date().getFullYear()}`}{' '}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights
        reserved.
      </Col>
      <Col span={12}>
        <p className="text-right">
          <Tooltip title="Versión de la app">Versión {APP_VERSION}</Tooltip>
        </p>
      </Col>
    </Row>
  )
}
