import {
  TeamOutlined,
  UserAddOutlined,
  AppstoreOutlined,
  AppstoreAddOutlined,
  DollarOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
  FileAddOutlined,
} from '@ant-design/icons'
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  // {
  //   key: 'home',
  //   path: `${APP_PREFIX_PATH}/home`,
  //   title: 'home',
  //   icon: DashboardOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: 'clientes',
    title: 'Clientes',
    breadcrumb: false,
    submenu: [
      {
        key: 'cliente_registro',
        path: `${APP_PREFIX_PATH}/cliente/registro`,
        title: 'Nuevo cliente',
        icon: UserAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'cliente_lista',
        path: `${APP_PREFIX_PATH}/clientes/lista`,
        title: 'Lista de Clientes',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'programas',
    title: 'Programas',
    breadcrumb: false,
    submenu: [
      {
        key: 'programa_registro',
        path: `${APP_PREFIX_PATH}/programa/registro`,
        title: 'Registro de Programa',
        icon: AppstoreAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'programas_lista',
        path: `${APP_PREFIX_PATH}/programas/lista`,
        title: 'Lista de Programas',
        icon: AppstoreOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'pagos',
    title: 'Pagos',
    breadcrumb: false,
    submenu: [
      {
        key: 'pago_registro',
        path: `${APP_PREFIX_PATH}/pago/registro`,
        title: 'Registrar Pago',
        icon: PlusCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'pago_lista',
        path: `${APP_PREFIX_PATH}/pagos/lista`,
        title: 'Lista de pagos',
        icon: DollarOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'manuales',
    title: 'Manuales',
    breadcrumb: false,
    submenu: [
      {
        key: 'registrar_manual',
        path: `${APP_PREFIX_PATH}/manual/registrar`,
        title: 'Registrar manual',
        icon: FileAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'lista_manual',
        path: `${APP_PREFIX_PATH}/manuales`,
        title: 'Lista de manuales',
        icon: UnorderedListOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
]

const navigationConfig = [...dashBoardNavTree]

export default navigationConfig
