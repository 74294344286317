const { Modal } = require('antd')

export const successModalAlert = (message) => {
  Modal.success({
    title: 'Se han guardado los cambios con exito!',
    content: `${message}`,
  })
}

export const errorModalAlert = (
  message,
  title = 'No se realizaron cambios',
) => {
  console.log({ title })

  Modal.error({
    title,
    content: `${message}`,
  })
}
