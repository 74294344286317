import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { CLIENT_PREFIX_PATH } from 'configs/AppConfig'
import '../../../node_modules/bootstrap/dist/css/bootstrap.css'
import { State } from 'views/AppContext'

export const AppViews = () => {
  const { clientStatus } = useContext(State)
  const { is_register, has_payment, has_file } = clientStatus
  const history = useHistory()

  const getRedirect = useCallback(() => {
    if (!is_register) {
      return `${CLIENT_PREFIX_PATH}/post-registro`
    } else if (!has_payment) {
      return `${CLIENT_PREFIX_PATH}/pagar-plan`
    } else if (!has_file) {
      return `${CLIENT_PREFIX_PATH}/expediente`
    } else {
      return `${CLIENT_PREFIX_PATH}/menus`
    }
  }, [is_register, has_payment, has_file])

  useEffect(() => {
    if (!is_register || !has_payment || !has_file) {
      history.push(getRedirect())
    }
  }, [is_register, has_payment, has_file, history, getRedirect])

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${CLIENT_PREFIX_PATH}/home`}
          component={lazy(() => import(`./Home`))}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/expediente`}
          component={lazy(() =>
            import(`../app-views/clientes/ClientExpedient`),
          )}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/pagar-plan`}
          component={lazy(() => import(`./PayPlan`))}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/crear_cuenta`}
          component={lazy(() => import(`./PaymentMethod`))}
        />

        <Route
          path={`${CLIENT_PREFIX_PATH}/post-registro`}
          component={lazy(() => import(`../app-views/clientes/ClientCreate`))}
        />

        <Route
          exact={true}
          path={`${CLIENT_PREFIX_PATH}/seguimiento`}
          component={lazy(() =>
            import(`../../components/shared-components/ClientMonitoring`),
          )}
        />

        <Route
          path={`${CLIENT_PREFIX_PATH}/seguimiento/semana`}
          component={lazy(() => import(`./ClientMonitoringProcess`))}
        />

        <Route
          exact={true}
          path={`${CLIENT_PREFIX_PATH}/seguimiento/:id`}
          component={lazy(() => import(`./ClientMonitoringProcess`))}
        />

        <Route
          path={`${CLIENT_PREFIX_PATH}/menus`}
          component={lazy(() => import(`./Menus/Menus`))}
        />

        <Route
          path={`${CLIENT_PREFIX_PATH}/metodos-pago`}
          component={lazy(() => import(`./PaymentMethods`))}
        />

        <Route
          path={`${CLIENT_PREFIX_PATH}/metodo-pago/editar`}
          component={lazy(() =>
            import(`./components/PaymentMethod/EditPaymentMethod`),
          )}
        />

        <Route
          path={`${CLIENT_PREFIX_PATH}/manuales`}
          component={lazy(() => import(`./Manuals`))}
        />

        <Route
          path={`${CLIENT_PREFIX_PATH}/cuenta`}
          component={lazy(() => import(`./Account`))}
        />

        <Redirect from={`${CLIENT_PREFIX_PATH}`} to={getRedirect()} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews)
