import { HTTP } from './HttpService'

export const ClientService = {
  create(userData) {
    return HTTP.post('/cliente', userData)
  },
  update(id, userData) {
    return HTTP.put(`/cliente`, userData, {
      params: {
        cliente: id,
      },
    })
  },
  status(auth0_uid) {
    return HTTP.get(`/cliente/status/${auth0_uid}`)
  },
}
