import React, { useContext } from 'react'
import { connect } from 'react-redux'
import ClientHeaderNav from 'components/layout-components/ClientHeaderNav'
import { Layout } from 'antd'
import ClientViews from 'views/client-views/ClientViews'
import { State } from 'views/AppContext'

import './clientLayout.scss'
import ClientAppContext from 'views/client-views/ClientAppContext'
import { FooterContent } from 'components/layout-components/Footer'
const { Content } = Layout

export const ClientLayout = () => {
  const { clientStatus } = useContext(State)

  const showRoutes = () => {
    const { is_register, has_payment, has_file } = clientStatus

    return is_register && has_payment && has_file
  }

  return (
    <ClientAppContext>
      <Layout className="client-layout__container">
        <ClientHeaderNav showRoutes={showRoutes()} />
        <Content className="client-layout__content p-md-5 h-100">
          <ClientViews />
        </Content>
        <nav
          style={{ zIndex: 1 }}
          className="navbar fixed-bottom navbar-light bg-light pb-0"
        >
          <FooterContent />
        </nav>
      </Layout>
    </ClientAppContext>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme
  return { navCollapsed, navType, locale }
}

export default connect(mapStateToProps)(React.memo(ClientLayout))
