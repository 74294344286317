import React, { createContext, useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { setBearerToken } from 'services/HttpService'
import { AuthService } from 'services/AuthService'
import Loading from 'components/common/Loading'
import { ClientService } from 'services/ClientService'

export const State = createContext({
  role: null,
  clientStatus: null,
  setClientStatus: () => {},
})
const AppContext = ({ children }) => {
  const { getAccessTokenSilently, user } = useAuth0()
  const [role, setRole] = useState(null)
  const [retriedSession, setRetriedSession] = useState(false)

  const [clientStatus, setClientStatus] = useState({})
  /**
   * Get and set the role for the user.
   */
  useEffect(() => {
    const getRole = async () => {
      try {
        const token = await getAccessTokenSilently()

        setBearerToken(token)

        const { data } = await AuthService.verifyAccess()

        const { is_admin } = data

        if (!is_admin) {
          await getClientStatus()
        }

        setRole(is_admin ? 'admin' : 'cliente')
      } catch (err) {
        if (retriedSession) {
          console.error('Error al obtener el permiso del usuario')
        } else {
          setRetriedSession(true)
        }
      }
    }

    const getClientStatus = async () => {
      const { data } = await ClientService.status(user.sub)

      setClientStatus(data)
    }

    getRole()
  }, [getAccessTokenSilently, user.sub, retriedSession])

  return (
    <State.Provider
      value={{
        role,
        clientStatus,
        setClientStatus,
      }}
    >
      {role === null ? <Loading /> : children}
    </State.Provider>
  )
}

export default AppContext
